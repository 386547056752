// English
export default {
  SYSCOMMON: {
    yes: 'Yes',
    no: 'No',
    confirm: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    dashboard: 'Dashboard',
    close: 'Close',
    login: 'Sign in',
    logout: 'Sign out',
    go_to_app: 'App',
    sign_in_facebook: 'Sign with Facebook',
    accept_permission: 'Accept all permission to use our feature',
    home: 'Home',
    welcome: 'Welcome to Kommai',
    description1:
      "We're excited to have you here! To get started with Kommai, please configure your initial settings.",
    description2: 'We found {{count}} Facebook Pages managed by you.',
    note: "Don't worry, you can always modify these settings later in your account preferences.",
    selectPage: 'Select an Facebook Page',
    continue: 'Continue',
    noPages: 'No facebook pages available.',
    followers: 'followers',
    page: 'Page',
    connect: 'Connect',
    survey: 'Survey',
    skip: 'Skip',
    used: 'Used',
    disconnect: 'Disconnect',
    disconnect_page: 'Are you sure u want to disconnect page?',
    disconnect_page_desc:
      'After disconnecting page all your automation will stop working',
    automations: 'Automations',
    settings: 'Settings',
    home_page: 'Homepage',
    feedback: 'Feedback',
    profile: 'Profile',
    account: 'Account',
    not_found_code: '404',
    permission_denied_code: '403',
    denied_title: 'Permission Denied',
    denied_desc: "You don't have permission to access this page.",
    not_found_title: 'Page Not Found',
    not_found_desc:
      ' The page you are looking for does not exist or has been moved.',
    go_to_home: 'Go to Home',
    connected_pages: 'Connected pages',
    add_new_page: 'Manage pages',
    create: 'Create',
    add: 'Add',
    delete_title: 'Deleting item',
    delete_desc: 'Are you sure you want to delete this item?',
    delete: 'Delete',
    next: 'Next',
    name: 'Name',
    status: 'Status',
    edit: 'Edit',
    no_resources: 'No {{resources}}',
    switch_page: 'Switch page',
    switch_page_desc: 'Are you sure you want to switch page?',
    switch: 'Switch',
    automation: 'Automation',
    empty_desc:
      '"It looks like there are no {{resources}} here yet. Please create new',
    loading_resource: 'Loading {{resources}}',
    go_to_automation: 'Go to automation',
    no_data: 'There is no data to show please go to automations page',
    reports: 'Reports',
    facebook_login: 'Log In with Facebook',
    facebook_pages: 'Facebook pages',
  },
  LOGIN: {
    welcomeTitle: 'Welcome to Kommai',
    welcomeSubtitle: 'Automate your Facebook interactions with AI',
    welcomeDescription:
      'Our app uses advanced AI to automatically reply to comments on your Facebook pages, saving you time and improving engagement.',
    signIn: 'Sign in',
    permissionInfo:
      'Please accept all required Facebook permissions and select the pages you want to manage to use our service.',
    loggingIn: 'Logging in...',
    loginError: 'Error: {{error}}',
  },
  HOME: {
    home: 'Home',
    about: 'About',
    features: 'Features',
    features_desc:
      'Elevate your Facebook page management with our AI-powered tools',
    testimonials: 'Testimonials',
    Pricing: 'Pricing',
    faq: 'Frequently asked questions',
    product: 'Product',
    company: 'Company',
    legal: 'Legal',
    privacy: 'Privacy Policy',
    terms: 'Terms of Service',
    ai: 'AI driven',
    slogan_one: ' Social Engagement',
    slogan_two: 'Smart Replies, Real Connections',
    agree_before_continue: 'By clicking `Start now` you agree to our',
    start_now: 'Start now',
    contact: 'Contact Us',
    description:
      'Kommai is an AI-driven automated reply solution that takes your Facebook page communication to the next level. Our intelligent system selects the most appropriate response from your pre-created answers and replies to each comment without your intervention. This saves your time and resources while increasing customer satisfaction. With simple steps of connecting to Facebook, selecting your pages, and creating automations, you can get started in minutes. With Kommai, your business is always on, 24/7.',
  },
  SURVEY: {
    company_type: 'What type of company do you work for?',
    company_type_desc:
      "Select the option that best describes your company's primary business area.",
    role: 'What is your role in the company?',
    role_desc:
      'Choose the job title or position that most closely matches your responsibilities.',
    agency_size: 'How many employees does your company have?',
    agency_size_desc:
      'Select the range that best represents the number of employees in your organization.',
    select_company_type: 'Select company type',
    select_role: 'Select role',
    select_company_size: 'Select company size',
  },
  AUTOMATION: {
    responses: 'Responses',
    last_modified: 'Modified',
    create_automation: 'Create Automation',
    name: 'Name',
    select_post: 'Select Post',
    search_posts: 'Search posts',
    post: 'Post',
    comment_responses: 'Comment responses',
    keyword: 'Keyword',
    content: 'Comment content',
    add_response: 'Add response',
    create_response: 'Create response',
    content_help:
      'Enter the content that will be posted as a comment. This content will be used when the AI selects the corresponding keyword from a Facebook post.',
    keyword_help:
      'Enter a keyword that represents the meaning of your content. The AI will analyze user comments and choose the most suitable keyword for reply. Examples: price, location, product detail. Note: Choose keywords that accurately represent your content as the AI only matches keywords, not the actual content.',
    pause: 'Pause',
    activate: 'Activate',
    pause_title: 'Pause',
    pause_desc: 'Are you sure you want to pause this automation?',
    resume_title: 'Activate',
    resume_desc: 'Are you sure you want to activate this automation?',
    delete_title: 'Deleting automation',
    delete_desc: 'Are you sure you want to delete this automation?',
  },
  FORM_DESC: {
    automation_name: 'Automation Name',
    automation_post: 'Post connected to this automation',
    automation_keyword: 'Keyword for AI',
    automation_contennt: 'Auto-reply content',
    automation_status: 'Automation status',
  },
  ROUTES: {
    dashboard: 'Dashhboard',
    automation: 'Automations',
    edit: 'Edit',
    connect: 'Connect',
  },
  ERROR: {
    E000001: '{{field}} is required',
    E000002: 'Field is required',
    E000003: 'Passwords should match',
    E000004: '{{field}} must be in the future',
    E000005: 'End date must be greater',
    E000006: 'Invalid Date',
    E000007: 'Start at must be lesser',
    E000008: 'Minimum limit is 30 mins',
    E000009: 'Change to compensatory request',
    E000010: 'Both dates must be on same day',
    E000046: '{{field}} must be within {{number}} characters',
    E000047: '{{field}} must be at least {{number}} characters',
    E000048: '{{field}} must be valid',
    E000049: '{{field}} has invalid character(s)',
    E000050: '{{field}} needs to be {{number}} character(s)',
    E000051: '{{field}} must contain number',
    E000052: '{{field}} must contain lowercase letter',
    E000053: '{{field}} must contain uppercase letter',
    E000054: '{{field}} must contain at least one of these !@#$%^&*()-+',
    E000055: 'Field values is empty',
    E000056: 'Enter valid phone number',
    E000057: '{{field}} must contain a letter',
    E000058: 'Enter different password than old password',
    E000060: 'Start date is required',
    E000061: 'Start end dates must be same month',
    E000062: 'Failed to delete item',
    E000063: 'Enter valid number',
    E000064: 'Cost start date must be lesser than expire date',
    E000065: "Can't select any date before previous month",
    E000066: 'Failed to upload',
    E000067: 'End time must be greater',
    E000068: 'Start time must be in future',
    E000069: '{{field}} Must be unique',
  },
}
