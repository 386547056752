export default {
  SYSCOMMON: {
    yes: 'Тийм',
    no: 'Үгүй',
    confirm: 'Зөвшөөрөх',
    cancel: 'Цуцлах',
    save: 'Хадгалах',
    dashboard: 'Хянах самбар',
    close: 'Хаах',
    login: 'Нэвтрэх',
    logout: 'Гарах',
    go_to_app: 'Апп',
    sign_in_facebook: 'Facebook-ээр нэвтрэх',
    accept_permission:
      'Манай үйлчилгээг ашиглахын тулд бүх зөвшөөрлийг хүлээн зөвшөөрөх',
    home: 'Эхлэл',
    welcome: 'Kommai-д тавтай морил',
    description1:
      'Манай Kommai системд тавтай морилно уу! Ашиглаж эхлэхийн тулд анхны тохиргоогоо хийнэ үү.',
    description2: 'Бид таны удирдагддаг {{count}} Facebook хуудсыг оллоо.',
    note: 'Санаа бүү зов, та эдгээр тохиргоог дараа нь өөрийн бүртгэлийн тохиргоонд өөрчлөх боломжтой.',
    selectPage: 'Фэйсбүүк хуудас сонгох',
    continue: 'Үргэлжлүүлэх',
    noPages: 'Фэйсбүүк хуудас байхгүй байна.',
    followers: 'дагагч',
    page: 'Хуудас',
    connect: 'Холбох',
    survey: 'Судалгаа',
    skip: 'Алгасах',
    used: 'Ашиглагдсан',
    disconnect: 'Салгах',
    disconnect_page: 'Are you sure u want to disconnect page?',
    disconnect_page_desc:
      'After disconnecting page all your automation will stop working',
    automations: 'Автоматжуулалт',
    settings: 'Тохиргоо',
    home_page: 'Нүүр хуудас',
    feedback: 'Санал гомдол',
    profile: 'Профайл',
    account: 'Хэрэглэгч',
    not_found_code: '404',
    permission_denied_code: '403',
    denied_title: 'Эрхгүй байна',
    denied_desc: 'Та энэ хуудсанд хандах эрхгүй байна',
    not_found_title: 'Хуудас олдсонгүй',
    not_found_desc: ' Таны хайж буй хуудас байхгүй эсвэл зөөсөн байна.',
    go_to_home: 'Эхлэл рүү буцах',
    connected_pages: 'Холбогдсон хуудсууд',
    add_new_page: 'Пэйж тохиргоо',
    create: 'Үүсгэх',
    add: 'Нэмэх',
    delete_title: 'Устгах',
    delete_desc: 'Та энэ мэдээллээ устгахдаа итгэлтэй байна уу?',
    delete: 'Устгах',
    next: 'Дараагийнх',
    name: 'Нэр',
    status: 'Төлөв',
    edit: 'Өөрчлөх',
    no_resources: '{{resources}} байхгүй',
    switch_page: 'Хуудас солих',
    switch_page_desc: 'Та одоо байгаа хуудсаа солихдоо итгэлтэй байна уу?',
    switch: 'Солих',
    automation: 'Автоматжуулалт',
    empty_desc: 'Хоосон байгаа учир шинээр нэмнэ үү',
    loading_resource: '{{resources}}-г уншиж байна',
    go_to_automation: 'Автоматжуулалт руу очих',
    no_data: 'Харуулах мэдээлэл одоогоор алга. Автоматжуулалт руу очино уу.',
    reports: 'Тайлан',
    facebook_login: 'Фэйсбүүкээр нэвтрэх',
    facebook_pages: 'Фэйбүүк хуудсууд',
  },
  LOGIN: {
    welcomeTitle: 'Тавтай морил',
    welcomeSubtitle: 'Facebook харилцаагаа AI-тай автоматжуулаарай',
    welcomeDescription:
      'Манай апп дэвшилтэт AI ашиглан таны Facebook хуудсан дахь сэтгэгдлүүдэд автоматаар хариу өгч, цаг хэмнэж, харилцаагаа сайжруулна.',
    signIn: 'Нэвтрэх',
    permissionInfo:
      'Манай үйлчилгээг ашиглахын тулд Facebook-ийн шаардлагатай бүх зөвшөөрлийг хүлээн зөвшөөрч, удирдах хуудсуудаа сонгоно уу',
    loggingIn: 'Нэвтэрч байна...',
    loginError: 'Алдаа: {{error}}',
  },
  HOME: {
    home: 'Нүүр хуудас',
    about: 'Бидний тухай',
    features: 'Онцлог шинж чанарууд',
    features_desc: 'Фэйсбүүк хуудсаа хиймэл оюун ухаанаар...',
    testimonials: 'Сэтгэгдлүүд',
    Pricing: 'Үнийн санал',
    faq: 'Түгээмэл асуултууд',
    product: 'Бүтээгдэхүүн',
    company: 'Компани',
    legal: 'Эрх зүй',
    privacy: 'Нууцлалын бодлого',
    terms: 'Үйлчилгээний нөхцөл',
    ai: 'AI ашиглан',
    slogan_one: ' бизнесээ тэл',
    slogan_two: 'Ухаалаг хариулт, Амьд харилцаа',
    agree_before_continue: 'Үргэлжлүүлэхээсээ өмнө манай',
    start_now: 'Эхлэх',
    contact: 'Холбоо барих',
    description:
      'Kommai нь таны Facebook хуудсын харилцаа холбоог шинэ түвшинд гаргах AI-д суурилсан автомат хариулт өгөх шийдэл юм. Бидний ухаалаг систем нь таны урьдчилан бэлтгэсэн хариултуудыг ашиглан сэтгэгдэл бүрт тохирох хариултыг сонгож, таны оролцоогүйгээр хариу өгнө. Энэ нь таны цаг хугацаа, нөөцийг хэмнэхийн зэрэгцээ үйлчлүүлэгчдийн сэтгэл ханамжийг нэмэгдүүлнэ. Facebook-тэй холбогдох, хуудсаа сонгох, автоматжуулалт үүсгэх гэсэн энгийн алхмуудаар та хэдхэн минутын дотор эхлэх боломжтой. Kommai-тай хамт таны бизнес 24/7 ажиллах болно.',
  },
  SURVEY: {
    company_type: 'Та ямар төрлийн компанид ажилладаг вэ?',
    company_type_desc:
      'Таны компанийн үндсэн бизнесийн чиглэлийг хамгийн сайн тодорхойлох сонголтыг сонгоно уу.',
    role: 'Таны компан дахь үүрэг юу вэ?',
    role_desc:
      'Таны үүрэг хариуцлагатай хамгийн сайн тохирох ажлын байр эсвэл албан тушаалыг сонгоно уу.',
    agency_size: 'Таны компанид хэдэн ажилтан байдаг вэ?',
    agency_size_desc:
      'Таны байгууллагын ажилтны тоог хамгийн сайн илэрхийлэх хүрээг сонгоно уу.',
    select_company_type: 'Компанийн төрлийг сонгох',
    select_role: 'Үүргийг сонгох',
    select_company_size: 'Компанийн хэмжээг сонгох',
  },
  AUTOMATION: {
    responses: 'Хариулт',
    last_modified: 'Өөрчлөгдсөн',
    create_automation: 'Автоматжуулалт үүсгэх',
    name: 'Нэр',
    select_post: 'Пост сонгох',
    search_posts: 'Пост хайх',
    post: 'Пост',
    comment_responses: 'Сэтгэгдлийн хариултууд',
    keyword: 'Түлхүүр үг',
    content: 'Агуулга',
    add_response: 'Хариулт нэмэх',
    create_response: 'Хариулт үүсгэх',
    content_help:
      'Сэтгэгдэл болгон нийтлэх агуулгыг оруулна уу. Энэ агуулга нь AI хэрэглэгчийн сэтгэгдэлд үндэслэн харгалзах түлхүүр үгийг сонгосон үед ашиглагдана.',
    keyword_help:
      'Таны агуулгын утгыг төлөөлөх түлхүүр үгийг оруулна уу. AI хэрэглэгчдийн сэтгэгдлийг шинжилж, хариулт өгөхөд хамгийн тохиромжтой түлхүүр үгийг сонгоно. Жишээ нь: үнэ, байршил, бүтээгдэхүүний дэлгэрэнгүй. Анхааруулга: AI зөвхөн түлхүүр үгийг тааруулдаг, бодит агуулгыг биш тул таны агуулгыг нарийн төлөөлөх түлхүүр үгийг сонгоно уу.',
    pause: 'Зогсоох',
    activate: 'Идвэхжүүлэх',
    pause_title: 'Автоматжуулалтын зогсоох',
    pause_desc: 'Та энэ автоматжуулалтыг зогсоох гэхдээ итгэлтэй байна уу?',
    resume_title: 'Автоматжуулалтыг идвэхжүүлэх',
    resume_desc: 'Та энэ автоматжуулалтыг идвэх гэхдээ итгэлтэй байна уу?',
    delete_title: 'Устгах',
    delete_desc: 'Та энэ мэдээллээ устгахдаа итгэлтэй байна уу?',
    delete: 'Устгах',
  },
  FORM_DESC: {
    automation_name: 'Автоматжуулалтын нэр',
    automation_post: 'Таны холбосон пост',
    automation_keyword: 'AI-д зориулсан түлхүүр үг',
    automation_contennt: 'Автомат хариултын агуулга',
    automation_status: 'Автоматжуулалтын төлөв',
  },
  ROUTES: {
    dashboard: 'Эхлэл',
    automation: 'Автоматжуулалт',
    edit: 'засах',
    connect: 'Холболт',
  },
  ERROR: {
    E000001: '{{field}} оруулах',
    E000002: 'Утга оруулах шаардлагатай',
    E000003: 'Оруулсан 2 нууц үг ижил байх ёстой',
    E000004: '{{field}} ирээдүй цаг дээр байх ёстой',
    E000005: 'Дуусах огноо их байх ёстой',
    E000006: 'Хүчингүй огноо',
    E000007: 'Эхлэх огноог багасгана уу',
    E000008: 'Хамгийн багадаа 30 минутын зөрүүтэй байх',
    E000009: 'Change to compensatory request',
    E000010: 'Өдрүүд нь ижил өдөрт байх ёстой',
    E000046: '{{field}} нь {{number}} тэмдэгт дотор байх ёстой',
    E000047: '{{field}} хамгийн багадаа {{number}} тэмдэгт байх ёстой',
    E000048: '{{field}} шаардлага хангах ёстой',
    E000049: '{{field}} буруу тэмдэгттэй байна',
    E000050: '{{field}} нь {{number}} тэмдэгт байх шаардлагатай',
    E000051: '{{field}} тоо агуулсан байх ёстой',
    E000052: '{{field}} нь жижиг үсэг агуулсан байх ёстой',
    E000053: '{{field}} нь том үсэг агуулсан байх ёстой',
    E000054:
      '{{field}} нь эдгээрийн дор хаяж нэгийг агуулсан байх ёстой !@#$%^&*()-+',
    E000055: 'Талбарын утга хоосон байна',
    E000056: 'Зөв утасны дугаар оруулна уу',
    E000057: '{{field}} must contain a letter',
    E000058: 'Хуучин нууц үгээс өөр нууц үг оруулна уу',
    E000060: 'Эхлэх огноог оруулах шаардлагатай',
    E000061: 'Эхлэх дуусах огноо нь нэг сар байх ёстой',
    E000062: 'Устгаж чадсангүй',
    E000063: 'Зөв тоон утга оруулна уу',
    E000064: 'Өртгийн эхлэх огноо дуусах хугацаанаас бага байх ёстой',
    E000065: 'Өмнөх сараас өөр огноо сонгох боломжгүй',
    E000066: 'Байршуулж чадсангүй',
    E000067: 'Дуусах цаг хойно байх ёстой',
    E000068: 'Эхлэх цаг ирээдүйд байх ёстой',
    E000069: '{{field}} Давтагдахгүй байх',
  },
}
